import React from 'react';

import Header from '../components/header';
import CardList from '../components/cardlist';

import Repository from '../Repository';
import LessonPack from '../services/LessonPackService';
import BackButton from '../components/backbutton';

export default class LessonController extends React.Component {
    
    lessonName() {
        const lessonPack = new LessonPack();
        const repository = new Repository();

        const lessonId = repository.getLessonId();
        const lessons = lessonPack.getAllLessons();
        const lesson = lessons[lessonId];

        return lesson.name;
    }

    render() {
        return(
            <>
                <BackButton path="/subject" marginTop="20px" />

                <Header>{ this.lessonName() }</Header>

                <div className="card-container">
                    <CardList />
                </div>
            </>
        );
    }
}
