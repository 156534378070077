import React from 'react';

import './styles.css';

export default class Completed extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="text-container">
                <h1>Parabéns! Você completou todas as lições deste cartão.</h1>
            </div>
        );
    }
}
