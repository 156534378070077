import React from 'react';

import './styles.css';
import { Link } from 'react-router-dom';

export default class BackButton extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <Link to={ this.props.path }>
                <button id="back" style={ {
                    marginTop: this.props.marginTop
                    } }>Voltar</button>
            </Link>
        );
    }
}
