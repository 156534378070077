import React from 'react';

import './styles.css';

import NotFoundController from '../../controllers/NotFoundController';

export default class NotFound extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <NotFoundController />
        );
    }
}
