import React from 'react';

import './styles.css';

export default class Header extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="header-container">
                <p id="title">{ this.props.children }</p>
            </div>
        );
    }
}
