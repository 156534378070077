import React from 'react';

import './styles.css';

import SubjectController from '../../controllers/SubjectController';

export default class Subject extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <SubjectController />
        );
    }
}
