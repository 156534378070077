import SimpleTextFactory from './factories/SimpleTextFactory';
import QuestionFactory from './factories/QuestionFactory';
import CompletedFactory from './factories/CompletedFactory';

export default function InteractionFactory(type, interaction, onPrevious, onNext) {

    switch(type) {
        case "simple_text": return SimpleTextFactory(
            interaction.text, onPrevious, onNext
        );

        case "question": return QuestionFactory(
            interaction.question,
            interaction.answer_one,
            interaction.answer_two,
            interaction.answer_three,
            interaction.correct_answer,
            onNext
        );

        case "completed": return CompletedFactory();
        default: return null;
    }
}
