export default class Repository {

     getLessonId() {
        return localStorage.getItem('lessonId');
    }

    setLessonId(id) {
        localStorage.setItem('lessonId', id);
    }

    getCardId() {
        return localStorage.getItem('cardId');
    }

    setCardId(id) {
        localStorage.setItem('cardId', id);
    }

    getInteractionId() { 
        return localStorage.getItem('interactionId');
    }

    getCurrentProgress() {
        return localStorage.getItem('currentProgress');
    }

    clearProgress() {
        localStorage.setItem('currentProgress', 0);
        localStorage.setItem('interactionId', 0);
    }

    incrementCurrentProgress() {
        const oldProgress = this.getCurrentProgress();
        const newProgress = Number(oldProgress) + 1;
        localStorage.setItem('currentProgress', newProgress);

        console.log("newProgress: " + Number(newProgress));

        var oldInteractionId = this.getInteractionId();
        var newInteractionId = Number(oldInteractionId) + 1;
        localStorage.setItem('interactionId', newInteractionId);

        console.log("newInteractionId: " + Number(newInteractionId));
    }

    decrementCurrentProgress() {
        const oldProgress = this.getCurrentProgress();
        const newProgress = Number(oldProgress) - 1;
        localStorage.setItem('currentProgress', newProgress);

        console.log("newProgress: " + Number(newProgress));

        var oldInteractionId = this.getInteractionId();
        var newInteractionId = Number(oldInteractionId) - 1;
        localStorage.setItem('interactionId', newInteractionId);

        console.log("newInteractionId: " + Number(newInteractionId));
    }

    getMaxProgress() {
        return localStorage.getItem('maxProgress');
    }

    setMaxProgress(max) {
        localStorage.setItem('maxProgress', max);
    }

    hasCompleted() {
        return this.getCurrentProgress() === this.getMaxProgress();
    }
}
