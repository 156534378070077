import React from 'react';

import Card from '../../components/carditem';

import './styles.css';

import { Link } from 'react-router-dom';
import Repository from '../../Repository';
import LessonPack from '../../services/LessonPackService';

export default function CardList() {

    const lessonPack = new LessonPack();
    const repository = new Repository();

    const lessonId = repository.getLessonId();
    const lessons = lessonPack.getAllLessons();
    const lesson = lessons[lessonId];

    const cardItems = [];

    for (let i = 0; i < lesson.cards.length; i++) {
        cardItems.push(
            <Link to="/interaction" key={ i } style={ { textDecoration: 'none' } }>
                <Card id={ i } />
            </Link>
        );
    };

    return(
        <div className="card-grid">
            { cardItems }
        </div>
    );
}
