import React from 'react';

import Repository from '../../Repository';
import LessonPack from '../../services/LessonPackService';
import InteractionFactory from './InteractionFactory';

const repository = new Repository();

export default class InteractionController extends React.Component {
    
    constructor(props) {
        super(props)

        this.showPrevious = this.showPrevious.bind(this);
        this.showNext = this.showNext.bind(this);

        this.componentDidMount = () => {
            this.forceUpdate();
        }
    }

    showPrevious() {
        if (repository.getCurrentProgress() > 0) {
            repository.decrementCurrentProgress();
            this.forceUpdate();
        }
    }

    showNext() {
        repository.incrementCurrentProgress();
        this.forceUpdate();
    }

    render() {
        const lessonId = repository.getLessonId();
        const cardId = repository.getCardId();
        const interactionId = repository.getInteractionId();

        const lessonPack = new LessonPack();

        const interaction = lessonPack.getInteraction(
            lessonId, cardId, interactionId
        );

        let type = 
        repository.hasCompleted() ? "completed" : interaction.interaction_type;

        return InteractionFactory(
            type, 
            interaction,
            this.showPrevious,
            this.showNext
        );
    }
}
