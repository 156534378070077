import React from 'react';

import './styles.css';

const TOAST_LENGTH_IN_MILLISECONDS = 1750;
const TOAST_AFTER_ACTION_TIMEOUT_IN_MILLISECONDS = 400;

const ANSWER_ONE = 1;
const ANSWER_TWO = 2;
const ANSWER_THREE = 3;

const CORRECT_ANSWER_TEXT = "Resposta correta. Siga em frente!";
const INCORRECT_ANSWER_TEXT = "Resposta incorreta. Avalie as outras opções.";

const CORRECT_MESSAGE_COLOR = "#00B47D";
const INCORRECT_MESSAGE_COLOR = "#E63232";

export default function Question(props) {

    let isShowingToast = false;
    let isCorrectAnswer = false;

    return(
            <div className="question-container">
                <h1 id="question">{ props.question }</h1>

                <div className="answer-container">
                    <button
                        id="answer"
                        onClick={ () => {
                            onClick(ANSWER_ONE)
                            } }>{ props.answerOne }</button>

                    <button
                        id="answer"
                        onClick={ () => {
                            onClick(ANSWER_TWO)
                            } }>{ props.answerTwo }</button>

                    <button
                        id="answer"
                        onClick={ () => {
                            onClick(ANSWER_THREE)
                            } }>{ props.answerThree }</button>
                </div>

                <div id="toast"><p /></div>
            </div>
    );

    function onClick(answerPosition) {
        if (isShowingToast === false) {
            isShowingToast = true;
            showToast();

            console.log("actual " + answerPosition);
            console.log("correct " + props.correctAnswer);

            const toast = document.getElementById('toast');
            toast.style.paddingTop = '20px';
            toast.style.paddingLeft = '20px';

            if (answerPosition === props.correctAnswer) {
                isCorrectAnswer = true;

                setTimeout(function() {
                    toast.textContent = CORRECT_ANSWER_TEXT;
                    toast.style.backgroundColor = CORRECT_MESSAGE_COLOR;
                }, 100);
            } else {
                setTimeout(function() {
                    toast.textContent = INCORRECT_ANSWER_TEXT;
                    toast.style.backgroundColor = INCORRECT_MESSAGE_COLOR;
                }, 100);
            }
        }
    }

    function showToast() {
        const toast = document.getElementById('toast');
        toast.style.height = 200 + 'px';

        setTimeout(function() {
            toast.style.height = 0 + 'px';
            toast.style.paddingTop = '0px';
            toast.style.paddingLeft = '0px';
            toast.textContent = "";
        }, TOAST_LENGTH_IN_MILLISECONDS);

        setTimeout(function() {
            toast.style.paddingTop = '0px';
            toast.style.paddingLeft = '0px';
            toast.textContent = "";
        }, TOAST_LENGTH_IN_MILLISECONDS + 175);

        setTimeout(function() {
          toast.style.backgroundColor = 'transparent';
            proceed();
        }, TOAST_LENGTH_IN_MILLISECONDS + TOAST_AFTER_ACTION_TIMEOUT_IN_MILLISECONDS);
    }

    function proceed() {
        if (isCorrectAnswer) {
            props.onClick();
        }

        isShowingToast = false;
    }
}
