import React from 'react';

import './styles.css';

import LessonController from '../../controllers/LessonController';
import Repository from '../../Repository';
import { Redirect } from 'react-router-dom';

const repository = new Repository();

export default class Lesson extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        if (repository.getLessonId() == null) {
            console.log("Lesson is null");
    
            return(
                <Redirect to="/subject" />
            );
        }
    
        return(
            <LessonController />
        );
    }
}
