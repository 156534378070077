import React from 'react';

import Header from '../components/header';
import LessonList from '../components/lessonlist';

export default class SubjectController extends React.Component {

    render() {
        return(
            <>
                <Header>Gramática</Header>

                <div className="lessonpack-container">
                    <LessonList />
                </div>
            </>
        );
    }
}
