import React from 'react';

import './styles.css';

import Repository from '../../Repository';
import LessonPack from '../../services/LessonPackService';

export default function LessonItem(props) {

    const id = props.id;
    const lessonPack = new LessonPack();
    const lessons = lessonPack.getAllLessons();
    const lesson = lessons[id];

    return(
        <button className="lesson-item-container" onClick={ handleClick }>
            <p id="lesson-name">{ lesson.name }</p>
            <p id="card-number">{ lesson.cards.length + " cartões" }</p>
        </button>
    );

    function handleClick() {
        const repository = new Repository();
        repository.setLessonId(id);
    }
}
