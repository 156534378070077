import React from 'react';

import ProgressBar from '../../../components/progressbar';
import BackButton from '../../../components/backbutton';
import Completed from '../../../components/completed';

import Repository from '../../../Repository';

export default function CompletedFactory() {

    const repository = new Repository();
    
    return(
        <>
            <ProgressBar
            max={ repository.getMaxProgress() } 
            progress={ repository.getCurrentProgress() } />

            <BackButton path="/lesson" marginTop="4px" />

            <div className="interaction-container">
                <Completed />
            </div>
        </>
    );
}
