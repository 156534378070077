import React from 'react';

import './styles.css';

import Repository from '../../Repository';
import LessonPack from '../../services/LessonPackService';

export default function CardItem(props) {

    const repository = new Repository();
    const lessonId = repository.getLessonId();
    const cardId = props.id;

    const lessonPack = new LessonPack();
    const lessons = lessonPack.getAllLessons();
    const lesson = lessons[lessonId];
    const card = lesson.cards[cardId];

    return(
        <button className="card-item-container" onClick={ handleClick }>
            <p>{ card.name }</p>
        </button>
    );

    function handleClick() {
        repository.setCardId(cardId);
        repository.clearProgress();
    }
}
