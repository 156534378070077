import React from 'react';

import './global.css';

import Routes from './routes';

export default class App extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
          <Routes />
        );
    }
}
