import React from 'react';

import { Link } from 'react-router-dom';

import './styles.css';

import LessonItem from '../lessonitem';
import LessonPack from '../../services/LessonPackService';

export default function LessonList() {

    const lessonPack = new LessonPack();
    const lessons = lessonPack.getAllLessons();

    let lessonItems = [];

    for (let i = 0; i < lessons.length; i++) {
        lessonItems.push(
            <Link to="/lesson"  key={ i } style={ { textDecoration: 'none' } }>
                <LessonItem id={ i } />
            </Link>
        );
    };

    return(
        <div className="lesson-grid">
            { lessonItems }
        </div>
    );
}
