import React from 'react';

import './styles.css';

export default function SimpleText(props) {
    
    return(
        <div className="container">
            <div className="text-container">
                <h1>{ props.text }</h1>
            </div>

            <div className="button-container">
                <button 
                id="previous" 
                onClick={ props.onPrevious }>Anterior</button>

                <button 
                id="next" 
                onClick={ props.onNext }>Próximo</button>
            </div>
        </div>
    );
}
