import grammar from '../data/grammar';

export default class LessonPack {

    getAllLessons() {
        return grammar.lessons;
    }

    getLesson(id) {
        const lessons = this.getAllLessons();
        return lessons[id];
    }

    getCard(lessonId, cardId) {
        const lessons = this.getAllLessons();
        const lesson = lessons[lessonId];
        return lesson.cards[cardId];
    }

    getInteraction(lessonId, cardId, interactionId) {
        const lessons = this.getAllLessons();
        const lesson = lessons[lessonId];
        const card = lesson.cards[cardId];
        return card.interactions[interactionId];
    }
}
