import React from 'react';

import ProgressBar from '../../../components/progressbar';
import BackButton from '../../../components/backbutton';
import SimpleText from '../../../components/interaction/simpletext';

import Repository from '../../../Repository';

export default function SimpleTextFactory(text, onPrevious, onNext) {

    const repository = new Repository();

    return(
        <>
            <ProgressBar
            max={ repository.getMaxProgress() }
            progress={ repository.getCurrentProgress() } />

            <BackButton path="/lesson" marginTop="4px" />

            <div className="interaction-container">
                <SimpleText
                    text={ text }
                    onPrevious={ onPrevious }
                    onNext={ onNext } />
            </div>
        </>
    );
}
