import React from 'react';

import BackButton from '../components/backbutton';

export default class NotFoundController extends React.Component {

    render() {
        return(
            <>
                <BackButton path="/subject" marginTop="20px" />

                <div className="notfound-container">
                    <h1>Página não encontrada.</h1>
                </div>
            </>
        );
    }
}
