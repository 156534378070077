import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Subject from './pages/subject';
import Lesson from './pages/lesson';
import Interaction from './pages/interaction';
import NotFound from './pages/notfound';

export default function routes() {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={ Subject } />
                <Route path="/subject" component={ Subject } />
                <Route path="/lesson" component={ Lesson } />
                <Route path="/interaction" component={ Interaction } />
                <Route path="*" component={ NotFound } />
            </Switch>
        </BrowserRouter>
    );
}
