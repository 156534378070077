import React from 'react';

import './styles.css';

import InteractionController from '../../controllers/interaction/InteractionController';
import LessonPack from '../../services/LessonPackService';
import Repository from '../../Repository';
import { Redirect } from 'react-router-dom';

const repository = new Repository();

export default class Interaction extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        if (repository.getCardId() == null) {
            console.log("CardId is null");
    
            return(
                <Redirect to="/subject" />
            );
        }
    
        const lessonId = repository.getLessonId();
        const cardId = repository.getCardId();
    
        const lessonPack = new LessonPack();
        const card = lessonPack.getCard(lessonId, cardId);
    
        repository.setMaxProgress(card.interactions.length);
    
        return(
            <div className="page-container">
                <InteractionController />
            </div>
        );
    }
}
