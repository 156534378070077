import React from 'react';

import ProgressBar from '../../../components/progressbar';
import BackButton from '../../../components/backbutton';
import Question from '../../../components/interaction/question';

import Repository from '../../../Repository';

export default function QuestionFactory(
    question, answerOne, answerTwo, answerThree, correctAnswer, onNext
    ) {

    const repository = new Repository();

    return(
        <>
            <ProgressBar
            max={ repository.getMaxProgress() } 
            progress={ repository.getCurrentProgress() } />

            <BackButton path="/lesson" marginTop="4px" />

            <div className="interaction-container">
                <Question
                    question={ question }
                    answerOne={ answerOne }
                    answerTwo={ answerTwo }
                    answerThree={ answerThree }
                    correctAnswer={ correctAnswer }
                    onClick={ onNext } />
            </div>
        </>
    );
}
