import React from 'react';

import './styles.css';

export default class ProgressBar extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const progress = (100 / this.props.max) * this.props.progress;
    
        return (
            <div className="progressbar" style={ {
                width: `${ progress }%`
            } } />
        );
    }
}
